import { ExperimentType } from '@/services/Experiments/constants'
import { useMultiVariantExperiment } from '@/services/Experiments/hooks'
import { useIsPage } from '@/hooks/useIsPage'

export const useProductGridOptimizationExp = () => {
  const experiment = useMultiVariantExperiment(
    ExperimentType.DESKTOP_PRODUCT_GRID_OPTIMIZATION
  )

  const { SEARCH: isSearchPage } = useIsPage()
  const inExperimentFlow = Boolean(experiment.active && isSearchPage)
  const showLargerProducts = inExperimentFlow && experiment.variant1

  return {
    ...experiment,
    showLargerProducts,
    inExperimentFlow,
  }
}

// Do not reuse for any other experiment and clean up this breakpoint after the experiment is complete.
export const productGridExperimentBreakpoint = '(min-width: 1080px)'
